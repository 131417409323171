
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import PersonalKc from "./personalKc/index.vue";
import PersonalTtl from "./personalTtl/index.vue";
import PersonalSc from "./personalSc/index.vue";
import PersonalZdzk from "./personalZdzk/index.vue";
import PersonalKscf from "./personalKscf/index.vue";
import EditComp from "./edit/index.vue";
const info = require("./../../assets/imgs/pic_info.png"),
  kc = require("./../../assets/imgs/pic_kc.png"),
  ttl = require("./../../assets/imgs/pic_ttl.png"),
  zy_sc = require("./../../assets/imgs/pic_zy_sc.png"),
  zdzk = require("./../../assets/imgs/pic_zdzk.png"),
  kscf = require("./../../assets/imgs/pic_kscf.png");
@Component({
  name: "Personal",
  components: {
    PersonalKc,
    PersonalTtl,
    PersonalSc,
    PersonalZdzk,
    PersonalKscf,
    EditComp,
  },
  computed: {
    ...mapGetters("personal", ["personalData", "hasWorkFlag"]),
    slideList () {
      return [
        { text: this.$t('center.t1'), icon: info, componentName: "EditComp" },
        { text: this.$t('center.t2'),
          icon: kc,
          children:[
            { text: this.$t('center.t3'), icon: kc, componentName: "PersonalKc" }
           /* { text: this.$t('center.t4'), icon: ttl, componentName: "PersonalTtl" },
            { text: this.$t('center.t5'), icon: zy_sc, componentName: "PersonalSc" }*/
          ]
        },
        /*{ text: this.$t('center.t6'),
          icon: info,
          children:[
            { text: this.$t('center.t7'), icon: zdzk, componentName: "PersonalZdzk" },
            { text: this.$t('center.t8'), icon: kscf, componentName: "PersonalKscf" }
          ]
        }*/
      ]
    }
  },
  methods: {
    ...mapActions('personal', ['getDailyWorksCount'])
  }
})
export default class Personal extends Vue {
  getDailyWorksCount!: any
  personalData!: any;
  slideList!: any;
  hasWorkFlag!: boolean;
  private slidebarList: Array<any> = [
    { text: "个人信息", icon: info, componentName: "EditComp" },
    { text: "我的课程", icon: kc, componentName: "PersonalKc" },
    { text: "天天练", icon: ttl, componentName: "PersonalTtl" },
    { text: "作业收藏夹", icon: zy_sc, componentName: "PersonalSc" },
    { text: "自助调课转班", icon: zdzk, componentName: "PersonalZdzk" },
    { text: "考试查分", icon: kscf, componentName: "PersonalKscf" },
  ];
  // private slideList: Array<any> = [
  //   { text: "个人信息", icon: info, componentName: "EditComp" },
  //   { text: "学习",
  //     icon: kc,
  //     children:[
  //       { text: "我的课程", icon: kc, componentName: "PersonalKc" },
  //       { text: "做作业", icon: ttl, componentName: "PersonalTtl" },
  //       { text: "作业收藏夹", icon: zy_sc, componentName: "PersonalSc" }
  //     ]
  //   },
  //   { text: "教务",
  //     icon: info,
  //     children:[
  //       { text: "自助调课转班", icon: zdzk, componentName: "PersonalZdzk" },
  //       { text: "考试查分", icon: kscf, componentName: "PersonalKscf" }
  //     ]
  //   }
  // ];
  private componentName: string = 'EditComp'
  private editFlag = false;
  private curHasWorkFlag: boolean = false
  private flag = 0
  private menuFlag = false
  private flags = [false, false]
  private curPersonData: any = {}


  @Watch('hasWorkFlag', { immediate: true, deep: true })
  hasWorkFlagWatch(newVal: boolean): void {
    this.curHasWorkFlag = newVal
  }
  @Watch('personalData', { immediate: true, deep: true })
  personalDataWatch(newVal: any): void {
    console.log('监听到变化')
    const personalData: any = localStorage.getItem('singapore_personal_Data')
    if (personalData) {
      this.curPersonData = JSON.parse(personalData)
    } else {
      this.curPersonData = {}
    }
  }
  private changeComponent(item: any, index: number, type: number): void {
    console.log(item, index, type)
    if ((index === 0 && type === 0) || type === 1) {
      console.log('个人中心')
      this.componentName = item.componentName;
    }
    // if (index !== 0 && type === 0) {
    //   this.flag = index
    // }
    this.flag = index
  }
  mounted() {
    if (this.$route.query.openCourse === '1' && this.$route.query.lessonstatus) {
      this.menuFlag = true
      // this.changeComponent(this.slideList[1], -1, 0)
      setTimeout(() => {
        this.changeComponent(this.slideList[1], 1, 0)
        this.componentName = 'PersonalKc'
      },500)
    }
    (this as any).$eventBus.$on('updataApp', (flag: any) => {
      const _this: any = this
      _this.componentName = 'EditComp'
    });
  }
  beforeMount() {
 /*   console.log('变化了')
    let _this: any = this,*/
      // _store: any = _this.$store.getters["personal/personalData"];
    // this.getDailyWorksCount(_store.id);
  }
}
