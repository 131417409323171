
    import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'

    import { checkPhone } from './../../utils/validate'

    import { mapGetters } from 'vuex'

    @Component({
        name: 'EditName',
        computed: mapGetters('personal', ['attrEmptyFlag'])
    })
    export default class EditName extends Vue {
        @Prop() placeholder!: string
        @Prop() type!: any
        @Prop() id!: string
        @Prop() showPhoneType!: boolean
        private phoneType: string = '65'

        private phone: string = ''
        private status: number = 0

        @Watch('attrEmptyFlag', { immediate: true, deep: true })
        attrEmptyFlagWatch(newVal: number): void {
            let _this: any = this
            if(_this.$route.path == '/personal/index') {
                this.setPhone()
            }
        }
        private focusEvent(): void {
            this.status = 0
            this.usenameCb(0)
        }
        @Emit()
        private usenameCb(status: number): any {
            return { phone: this.phone, status: status }
        }
        private blurEvent (): void {
            if(this.phone) {
                if(this.phone || this.placeholder.indexOf('姓名') >= 0) {
                    this.status = 1
                } else {
                    this.status = 2
                }
            } else {
                if(this.id != 'standbyPhone') {
                    this.status = 2
                } else {
                    this.status = 0
                }
            }
            this.usenameCb(1)
        }
        private phoneTypeChange (): void {
            this.usenameCb(1)
        }
        private setPhone (): void {
            let personalData: any = localStorage.getItem('singapore_personal_Data')
            if(personalData) {
                personalData = JSON.parse(personalData)
                switch(this.type) {

                    case '1':
                        this.phone = personalData.name || personalData.stdname
                        break
                    case '2':
                        this.phone = personalData.phone
                        break
                    case '3':
                        this.phone = personalData.standbyPhone
                        break
                }
            }
        }
        beforeMount() {
            this.setPhone()
        }
    }
