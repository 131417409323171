
    import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
    import SelectSubject from './selectSubject/index.vue'
    import SelectClass from './selectClass/index.vue'
    import SelectTimes from './selectTimes/index.vue'
    import PersonalAgreement from './../../../../components/personalAgreement/index.vue'
    import { mapGetters, mapMutations } from 'vuex'
    import { Caxios } from './../../../../utils/axios'
    import CommonSelect from './../../../../components/commonSelect/index.vue'
    @Component({
        name: 'Detail',
        computed: {
            ...mapGetters('personal', ['personalData']),
            ...mapGetters('common', ['lang'])
        },
        components: { SelectSubject, SelectClass, SelectTimes, CommonSelect, PersonalAgreement },
        methods: {
            ...mapMutations('personal', ['setAllFlag'])
        }
    })
    export default class Detail extends Vue {
        personalData!: any
        setAllFlag!: any
        lang!: any

        private curPersonalData: any = {}
        private curSubjects: Array<any> = []
        private curClass: Array<any> = []
        private curTimes: Array<any> = []
        private countObj: any = {}
        private levelStr: string = ''
        private getListTargetObj: any = {}
        private hasSelected: boolean = false
        private startNumber: number = 0
        private startDate: string = ''

        private oldObj: any = {
            subject: '',
            classes: '',
            times: ''
        }
        private levelObj: any = {
            subject: '',
            grade: '',
            level: ''
        }
        private selectType: string = ''
        private commonSelectData: any = {}
        private selectTableItem: any = {}
        private tempObj: any = {
        }

        private protocolDataArr: Array<any> = [
            { protocolTitle: '调课协议', protocolPopType: 3 },
            { protocolTitle: '转班协议', protocolPopType: 4 }
        ]
        private protocolData: any = {}
        private protocolFlag: boolean = false

        @Watch('personalData', { immediate: true, deep: true})
        personalDataWatch(newVal: any): void {
            if(newVal && newVal.id) {
                this.curPersonalData = { ...newVal }
                this.scores(newVal.id)

                this.classscreenSubjects(newVal.id)
            }
        }
        // 获取级别信息
        private async scores (id: string) {
            let _this: any = this,
                _res: any = await Caxios.get({ url: `/api/${id}/scores?student_id=${id}` });
            this.levelStr = ''
            if(_res && Array.isArray(_res) && _res.length > 0) {
                _res.forEach((item: any, index: number): void => {
                    if(item.subject != '2') {
                        this.levelStr += `【${item.subject_name}-${item.level_name}】 `
                    }
                })
            } else if(_res && Array.isArray(_res) && _res.length == 0) {
                this.levelStr = '暂无'
                // _this.$message.warning('未进行学科评级')
            } else {
                this.levelStr = '暂无'
                // _this.$message.warning(_res.msg)
            }
        }
        // 选择学科之后调用接口，获取班级列表
        private async classscreenClasses (id: string) {
            // this.tempObj
            let _res: any = await Caxios.get({ url: `/api/classscreen/${this.curPersonalData.id}/classes?subject=${id}`})
            if(_res && Array.isArray(_res) && _res.length > 0) {
                this.curClass = _res
            }
        }
        // 选择学科的回调
        private selectsubjectCb (obj: any): void {
            if(obj.status != 0) {
                if(obj.subject) {
                    if(this.oldObj.subject != obj.subject) {
                        this.getListTargetObj = {}
                        this.countObj = {}
                        this.curTimes = []
                        this.curClass = []
                        this.levelObj.subject = obj.text
                        this.oldObj.subject = obj.subject
                        this.classscreenClasses(obj.subject)
                        this.getSingleLevel()
                    }
                } else {
                    this.levelObj = {}
                    this.oldObj.subject = ''
                }
            }
        }
        // 选择班级后，调用接口，初始化课次列表
        private async lessons (id: string) {
            let _res: any = await Caxios.get({ url: `/api/${id}/${this.curPersonalData.id}/lessons`})
            if(_res && Array.isArray(_res) && _res.length > 0) {
                this.curTimes = _res
            }
        }
        // 选择班级后，调用接口，初始化剩余调课、转班课次
        private async classchangecount (id: string) {
            let _res: any = await Caxios.get({ url: `/api/${id}/${this.curPersonalData.id}/classchangecount`})
            if(_res) {
                this.countObj = { ..._res }
            }
        }
        // 选择班级的回调
        private async selectclassCb (obj: any) {
            if(obj.status != 0) {
                if(obj.subject) {
                    if(this.oldObj.classes != obj.subject) {
                        this.countObj = {}
                        this.curTimes = []
                        this.oldObj.classes = obj.subject
                        this.lessons(obj.subject)
                        this.classchangecount(obj.subject)
                    }
                } else {
                    this.oldObj.classes = ''
                }
                if(obj.latitude) {
                    this.levelObj.grade = obj.latitude
                    this.getSingleLevel()
                } else {
                    this.levelObj = {}
                }
            }
        }
        private async getSingleLevel () {
            if(this.levelObj.grade && this.oldObj.subject) {
                let _res: any = await Caxios.get({ url: `/api/${this.curPersonalData.id}/scores/single?student_id=${this.curPersonalData.id}&subject=${this.oldObj.subject}&grade=${this.levelObj.grade}`})
                if(_res && Array.isArray(_res) && _res.length > 0) {
                    this.levelObj = { ..._res[0] }
                }
            }
        }
        // 课次选择的回调
        private curtimesCb (obj: any): void {
            if(obj.status != 0 && obj.id) {
                this.getListTargetObj = { ...obj }
                this.oldObj.times = obj.id
                this.startNumber = obj.number
                this.startDate = obj.starttimeStr
            }
        }
        // 单选框事件，调用接口，做是否选择内容的判断
        private async radioEvent (str: string) {
            let _this: any = this

            if(!this.oldObj.subject) {
                _this.$message.warning(`请选择调课转班的学科`)
                return
            }
            if(!this.oldObj.classes) {
                _this.$message.warning(`请选择调课转班的班级`)
                return
            }


            if(this.countObj.tkcount == 3 && str == 'LESSON') {
                _this.$message.warning(`您的调课次数已用完`)
                return
            }
            if(this.countObj.zbcount == 3 && str == 'CLASS') {
                _this.$message.warning(`您的转班次数已用完`)
                return
            }


            if(this.selectType != str) {
                if(str == 'LESSON') {
                    if(this.selectType == 'CLASS' || !this.oldObj.times) {
                        this.selectType = ''
                        this.startDate = ''
                        this.startNumber = 0
                        this.oldObj.times = ''
                        _this.$message.warning(this.lang === 'zh-CN' ? '请选择调课转班的课次' : `Please select the number of classes to be transferred or transferred`)
                    } else {
                        this.selectType = str
                    }
                } else {
                    this.curTimes.forEach((item: any, index: number): void => {
                        if(item.lessonstatus == '0' && !this.oldObj.times && !this.startNumber && !this.startDate) {
                            this.oldObj.times = item.id
                            this.startNumber = item.number
                            this.startDate = item.starttimeStr
                        }
                    })
                    this.selectType = str
                }
                this.setAllFlag(false)


                if(this.oldObj.times) {
                    this.protocolData = this.selectType == 'LESSON' ? { ...this.protocolDataArr[0] } : { ...this.protocolDataArr[1] }
                    let _url: string = str == 'LESSON' ?
                        `/api/change/${this.selectType}/${this.getListTargetObj.classid}/${this.getListTargetObj.number}/${this.curPersonalData.id}/listtargetclass` :
                        `/api/change/${this.selectType}/${this.oldObj.classes}/${this.startNumber}/${this.curPersonalData.id}/listtargetclass`
                    let _res: any = await Caxios.get({ url: _url})
                    if(_res && _res.items && Array.isArray(_res.items) && _res.items.length > 0) {
                        this.commonSelectData = { ..._res }
                    }
                } else {
                    this.commonSelectData = []
                }
            }
        }

        private commonselectCb (item: any): void {
            this.selectTableItem = { ...item }
        }
        private commonselectLat (item: any): void {
            this.tempObj = { ...item }
        }
        private async sureEvent () {
            let _this: any = this
            if(!this.tempObj.levels) {
                _this.$message.warning('请选择班型')
                return
            }
            if(!this.tempObj.areas) {
                _this.$message.warning('请选择校区')
                return
            }
            if(!this.tempObj.teachers) {
                _this.$message.warning('请选择老师')
                return
            }
            if(!this.selectTableItem.id) {
                _this.$message.warning('请选择上课时段')
                return
            }
            let data: any = {
                    changechannel: '2',
                    destclassid: this.selectTableItem.id,
                    number: this.startNumber,
                    oriclassid: this.oldObj.classes,
                    orilessonid: this.oldObj.times ? this.oldObj.times : this.curTimes[0].id,
                    stdid: this.personalData.id,
                    type: this.selectType
                };

            if(!this.curPersonalData.name || !this.curPersonalData.grade) {
                _this.$message.warning('当前信息不完整，请前往个人信息补全信息后在进行报名！')
                return
            }
            if(!this.hasSelected) {
                _this.$message.warning(`请勾选${this.protocolData.protocolTitle}`)
                return
            }
            let _res: any = await Caxios.post({ url: `/api/classchange/save`, data: data})
            if(_res == true || _res == 'true') {
                _this.$message.success(`${this.selectType == 'LESSON' ? '调课' : '转班'}成功`)
                this.callback()
            } else {
                _this.$message.warning(_res.msg)
            }
        }
        private closeEvent (): void {
            this.protocolFlag = false
        }

        @Emit()
        private callback (): string {
            return 'Entrance'
        }
        // 初始化学科列表
        private async classscreenSubjects (id: number) {
            let _res: any = await Caxios.get({ url: `/api/classscreen/${id}/subjects` });
            if(_res && Array.isArray(_res) && _res.length > 0) {
                this.curSubjects = _res
            }
        }
    }
