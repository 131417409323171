
    import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator'
    import { mapGetters } from 'vuex'

    @Component({
        name: 'SelectClass',
        computed: mapGetters('personal', ['allFlag'])
    })
    export default class SelectClass extends Vue {
        allFlag!: any
        @Prop() curClass!: Array<any>

        private subjectText: string = '请选择班级'
        private subject: string = ''
        private latitude: string = ''
        private itemClickedFalg: boolean = false
        private mouseoverFlag: boolean = false
        private activeIndex: number = -1
        private listFlag: boolean = false
        private curClassList: Array<any> = [
            { text: '请选择班级', id: '', latitude: ''}
        ]
        @Watch('allFlag', { immediate: true, deep: true })
        allFlagWatch(newVal: number): void {
            if(newVal != -1) {
                this.listFlag = false
                this.mouseoverFlag = false
                this.selectclassCb(1)
            }
        }
        @Watch('curClass', { immediate: true, deep: true })
        curClassWatch(newVal: Array<any>): void {
            this.curClassList = [ { text: '请选择班级', id: '', latitude: ''} ]
            if(newVal.length > 0) {
                this.curClassList = [
                    ...this.curClassList,
                    ...newVal
                ]
            } else {
                this.subjectText = '请选择班级'
                this.curClassList = [
                    { text: '请选择班级', id: '', latitude: ''}
                ]
                this.subject = ''
            }
        }
        private mouseoverEvent (): void {
            this.mouseoverFlag = true
            this.selectclassCb(0)
        }
        @Emit()
        private selectclassCb(status: number): any {
            return { subject: this.subject, status: status, text: this.subjectText, latitude: this.latitude }
        }
        private listFlagEvent (): void {
            this.listFlag = !this.listFlag
        }
        private changeActiveEvent (item: any, index: number): void {
            this.subjectText = item.text
            this.subject = item.id
            this.latitude = item.latitude
            this.itemClickedFalg = true
            this.mouseoverFlag = false
            this.listFlag = false
            this.selectclassCb(1)
        }
    }
