
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import { Caxios } from "./../../../utils/axios";
import PersonalAgreement from "./../../../components/personalAgreement/index.vue";
import Refund from "./../../../components/refund/index.vue";
import PayDialog from "./../../../components/payDialog/index.vue";
import PModal from "@/views/personal/components/pmodal.vue";
import HkHowpay from "@/views/personal/components/hkhowpay.vue"
import SModal from "@/views/personal/components/serviceAgree.vue"
import payTime from '@/components/payTime'
import Modal from "ant-design-vue/es/modal/Modal";
@Component({
  name: "PersonalKc",
  computed: {
    ...mapGetters("personalKc", ["courseTotal", "courseList"]),
    ...mapGetters('common', ['branchSchoolId', 'lang', 'branchschoolList']),
    data() {
      return {
        a: 1
      }
    },
    navList() {
      return [
        { label: this.$t('center.t61'), num: (this as any).waitNum, type: "wait", lessonType: "djf" },
        { label: this.$t('center.t62'), num: (this as any).onclassNum, type: "onclass", lessonType: "zzs" },
        { label: this.$t('center.t63'), num: (this as any).notStartedNum, type: "notStarted", lessonType: "jjs" },
        { label: this.$t('center.t64'), num: (this as any).closedNum, type: "closed", lessonType: "ysw" },
        { label: this.$t('center.t65'), num: (this as any).exitedNum, type: "exited", lessonType: "yqx" },
      ]
    },
    navLabel() {
      return [
        this.$t('center.t66'),
        this.$t('center.t67'),
        this.$t('center.t68'),
        this.$t('center.t69'),
        this.$t('center.t70'),
      ]
    }
  },
  methods: {
    ...mapActions("personalKc", ["setCourseTotal", "setCourseList"]),
  },
  components: { payTime, PersonalAgreement, Refund, PayDialog, PModal, HkHowpay, SModal },
})
export default class PersonalKc extends Vue {
  navList!: any
  lang!: any
  branchschoolList!: any
  private waitNum: any = 0;
  private onclassNum: any = 0;
  private notStartedNum: any = 0;
  private closedNum: any = 0;
  private exitedNum: any = 0;
  // 默认校区
  private branchschoolCode: any = '';
  // 切换卡片索引
  private activeIndex = 0;
  // 加载状态
  private loadingFlag = true
  // 课程列表
  private myCourseList: any = []
  // 学生信息
  private userInfo: any = {}
  // 是否显示支付
  private showPay: any = false
  // 支付参数
  private UEN: any = ''
  private OCBC_Bank_Account_Number: any = ''
  private PayNow_QR_Code: any = ''
  static userInfo: any;
  public greeFlag = false
  private currentPayOrderBranchschoolCode: any = ''

  created() {
    if (this.$route.query.tab) {
      this.activeIndex = Number(this.$route.query.tab)
    }
    console.log('Number(this.$route.query.tab)', Number(this.$route.query.tab))
    const singapore_personal_Data: any = localStorage.getItem('singapore_personal_Data')
    try {
      this.userInfo = JSON.parse(singapore_personal_Data)
      console.log('this.userInfo', this.userInfo)
      this.branchschoolCode = this.userInfo.branchSchoolCode
    } catch (e) {
      console.log('转行出错')
    }
    this.getWaitPay()
    this.getMyOrderListInfo('number', [])
  }
  /* 校区改变 */
  private async schoolChange(e: any) {
    this.loadingFlag = true
    let status = 'onclass'
    if (this.activeIndex === 2) {
      status = 'notStarted'
    } else if (this.activeIndex === 3) {
      status = 'closed'
    } else if (this.activeIndex === 4) {
      status = 'exited'
    }
    this.waitNum = 0;
    this.onclassNum = 0;
    this.notStartedNum = 0;
    this.closedNum = 0;
    this.exitedNum = 0;
    await this.getMyOrderListInfo('number', status)
    await this.getWaitPay()
  }
  /*我的课程-待缴费列表*/
  private async getWaitPay() {
    const res: any = await Caxios.post({ url: '/api/official/order/list/waitPay', data: { pageNum: 1, pageSize: 200, branchSchoolCode: this.branchschoolCode } })
    if (res.code === '10000') {
      // console.log('shuju11',res.data.data)
      (this as any).waitNum = res.data.data.length
      this.myCourseList = res.data.data
      this.loadingFlag = false
    }
  }
  /*我的课程-其余状态的订单列表*/
  private async getMyOrderListInfo(type: any, status: any) {
    console.log(999)
    const _this: any = this
    const res: any = await Caxios.post({
      url: '/api/official/order/myOrderListInfo', data: {
        branchSchoolCode: this.branchschoolCode,
        pageNum: 1,
        pageSize: 200,
        onClassStatusList: type === 'number' ? [] : [status]
      }
    })
    if (res.code === '10000') {
      if (type === 'number') {
        res.data.data.forEach((item: any, index: number): void => {
          (this as any)[`${item.onClassStatus}Num`] = item.sellListDtos.length
        })
      }
      if (res.data.data.length > 0) {
        console.log('res.data.data[this.activeIndex - 1]', res.data.data[this.activeIndex - 1])
        if (res.data.data[this.activeIndex - 1]) {
          this.myCourseList = res.data.data[this.activeIndex - 1].sellListDtos
        }
      } else {
        this.myCourseList = []
      }
      this.loadingFlag = false
    }
  }
  // 培训协议
  private toastMsg(): void {
    this.$message.error(this.lang === 'zh-CN' ? '请阅读并签字《方田培训服务协议》！' : 'Please read and sign the  Fangtian Training Service Agreement ！')
  }
  // 切换TAB
  private async changeActiveIndex(index: number) {
    this.loadingFlag = true
    this.activeIndex = index;
    if (index !== 0) {
      let status = 'onclass'
      if (index === 2) {
        status = 'notStarted'
      } else if (index === 3) {
        status = 'closed'
      } else if (index === 4) {
        status = 'exited'
      }
      await this.getMyOrderListInfo('number', status)
    } else {
      await this.getWaitPay()
    }
  }
  // 去支付
  private async payConfig(code: any) {
    console.log('去支付数据', code)
    this.currentPayOrderBranchschoolCode = code
    this.showPay = true
  }
  // 取消订单
  private async cancelPay(id: any) {
    const res: any = await Caxios.post({ url: '/api/official/order/remove', data: [id] })
    if (res.code === '10000') {
      this.getWaitPay()
      console.log('取消订单数据', res.data)
    }
  }
  // 取消订单
  private async refundTestOrder(item: any) {
    if (item.amount !== 0) {
      Modal.confirm({
        content: this.$t('center.t106'),
        onOk: async () => {
        }
      })
      return
    }
    Modal.confirm({
      content: this.$t('center.t105'),
      onOk: async () => {
        const res: any = await Caxios.post({ url: '/api/official/order/refundTestOrder', data: { orderNoList: item.orderNoList } })
        if (res.code === '10000') {
          this.changeActiveIndex(this.activeIndex)
        }
      }
    });
    /*this.$msg.modal({
      title: '提示',
      content: '确认退课吗',

    })*/
  }
  // 修改订单
  private async updateOrder(orderGoodsId: any, item: any) {
    const _this: any = this
    if (item.courseCategory === 'activity') {
      _this.$router.push(`/course/detail?courseid=${item.courseId}&branchSchoolCode=${item.branchSchoolCode}&classId=${item.classId}&pathType=updata`)
    } else {
      _this.$router.push(`/course/detail?courseid=0&orderGoodsId=${orderGoodsId}`)
    }
    /* const res: any = await Caxios.post({ url: '/api/order/refundTestOrder' ,data:{orderNoList}})
     if(res.code === '10000') {
       this.changeActiveIndex(this.activeIndex)
       console.log('取消订单数据', res.data)
     }*/
  }
  // 去上直播课
  private async goLive(item: any, lessonId: any) {
    console.log('去上课', item)
    const res: any = await Caxios.post({ url: '/api/official/order/currentLessons', data: item.orderGoodsId })
    if (res.code === '10000') {
      if (res.data.length === 0) {
        const msg1: any = this.$t('center.t101')
        this.$message.warning(msg1);
        return
      }
      let lessonItem: any = {}
      if (lessonId) {
        lessonItem = res.data.filter((itm: { id: any }) => itm.id === lessonId)[0]
      } else {
        lessonItem = res.data[0]
      }
      if (lessonItem.onClassChannel === 'offline') {
        const msg: any = this.$t('center.t100')
        this.$message.warning(msg);
        return
      }
      // 去上课
      await PersonalKc.getLiveUrl(lessonItem, this.userInfo.userId)
    }
  }
  // 获取直播地址
  private static async getLiveUrl(item: any, userId: any) {
    const res: any = await Caxios.get({ url: `${process.env.VUE_APP_LIVE_URL}/live/v2/get/liveVersion?lessonId=${item.id}&userId=${userId}&deviceType=web&userType=S&appKey=${process.env.VUE_APP_KEY}` })
    console.log('res', res)
    if (res.code === '10000') {
      window.open(res.data.url)
    }
  }
}
