
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";

import { Caxios } from "./../../../utils/axios";
let save_e = require("./../../../assets/imgs/save_e.png"),
  save_f = require("./../../../assets/imgs/save_f.png"),
  print_w = require("./../../../assets/imgs/print_w.png"),
  print_b = require("./../../../assets/imgs/print_b.png"),
  arraw_w = require("./../../../assets/imgs/arraw_w.png"),
  arraw_b = require("./../../../assets/imgs/arraw_b.png");
@Component({
  name: "PersonalSc",
  computed: {
    ...mapGetters("course", ["subject"]),
    ...mapGetters("personalCollection", ["collectionList"]),
    ...mapGetters("common", ['lang']),
    navList () {
      return [this.$t('center.t85'), this.$t('center.t86')]
    }
  },
  methods: {
    ...mapActions("personalCollection", ["setCollectionList"]),
  },
})
export default class PersonalSc extends Vue {
  subject!: Array<any>;
  lang!: any
  collectionList!: Array<any>;
  setCollectionList!: any;
  navList!: any
  /*private navList: Array<any> = ["笔记本", "错题本"];*/
  private activeIndex: number = 0;
  private saveEIcon: any = save_e;
  private saveFIcon: any = save_f;
  private myAnswerListFlag: Array<boolean> = [];
  private viewAnswerListFlag: Array<boolean> = [];
  private hasAnswerFlag: Array<boolean> = [];
  private curSubject: Array<any> = [];
  private curCollectionItems: Array<any> = [];
  private printIcon: any = print_b;
  private arrawIcon: any = arraw_b;
  private printNum: number = 0;
  private printFlag: Array<boolean> = [];
  private printList: Array<any> = [];
  private loadingFlag: boolean = true;
  private canClickFlag: boolean = false;

  private emptyCollectionItem: any = {
    question: {
      analysis: "",
      analysis_web: "",
      answer: "",
      answer_web: "",
      attached: "",
      contents: "",
      contents_web: "",
      degree_name: "",
      grade_name: "",
      id: 0,
      master_knowledge_name: null,
      option_list: null,
      options: null,
      options_map: null,
      options_web: "",
      optnum: null,
      pics: null,
      quest_from_name: "",
      quest_type: "",
      quest_type_name: "",
      remarks: "",
      score: 0,
      slave_knowledge_names: null,
      slave_knowledges: "",
      subject: "",
      subject_name: "",
      year: null,
      mineOptionMap: [],
    },
    answer: {
      answer: null,
      answerresult: "",
      answerstate: "",
      answertime: "",
      attached: "",
      classes: null,
      classid: null,
      classname: "",
      collected: null,
      correctattached: "",
      correctnum: "",
      correctpics: null,
      courseid: null,
      id: "",
      name: null,
      notebookid: null,
      number: null,
      pics: [],
      question: null,
      questionid: "",
      remark: null,
      revisestate: null,
      score: "",
      size: null,
      starttime: null,
      stdid: "",
      stdname: "",
      testid: "",
      voiurl: null,
      subject: "",
      lessonnumber: "",
      totallesson: "",
      answerid: "",
    },
  };

  private tempObj: any = {
    type: 2,
    stdid: "",
  };


  // 笔记信息列表，通过列表获取详情
  private async getCollectionList () {
    let { stdid, type } = this.tempObj,
      result: any = await Caxios.get({ url: `/api/${stdid}/notes?page=1&limit=1000&type=${type}&stdid=${stdid}` }),
      newVal: Array<any> = result.records;

      if (newVal && Array.isArray(newVal) && newVal.length > 0) {
        this.myAnswerListFlag = new Array(newVal.length).fill(false);
        this.viewAnswerListFlag = new Array(newVal.length).fill(false);
        this.hasAnswerFlag = new Array(newVal.length).fill(false);
        this.printFlag = new Array(newVal.length).fill(false);
        this.getCollectionItem(newVal);
      }
      this.canClickFlag = false
      this.loadingFlag = false
  }
  private getCollectionItem(newVal: Array<any>) {
    for (let i: any = 0, len: number = newVal.length; i < len; i++) {
      let _item: any = newVal[i];

      this.itemAjaxResult({
        questid: _item.questid,
        answer_id: _item.answer_id || '',
        idx: i,
        collected: _item.collected || 'Y',
        answerstate: _item.answerstate || '',
        notebookid: _item.notebookid || '',
        subject: _item.subject || '',
        lessonnumber: _item.lessonnumber || '',
        totallesson: _item.totallesson || '',
        answerid: _item.answer_id || '',
        classid: _item.classid || '',
        courseid: _item.courseid || ''
      })
    }
  }

  private async itemAjaxResult(obj: any) {
      let _this: any = this,
        {
          questid,
          answer_id,
          idx,
          collected,
          answerstate,
          notebookid,
          subject,
          lessonnumber,
          totallesson,
          answerid,
          classid,
          courseid,
        } = obj,
        question: any = await Caxios.get({
          url: `/api/questions?ids=${questid}`,
        }),
          answer: any = undefined,
        _tempRes: any = { ...this.emptyCollectionItem };
      /*
      * 绘制题目
      * 1 单选题
      * 2 多选题
      * 3 主观填空题--拍照上传
      * 4 主观题--拍照上传
      * 5 数字填空题--拍照上传-暂不考虑
      * 6 判断题--拍照上传
      * 7 作文
      */
      if (Array.isArray(question) && question.length > 0) {
        _tempRes.question = { ...question[0] };
        let answer: any = undefined;
        if (answer_id) {
          answer = await Caxios.get({ url: `/api/answer/${answer_id}` });
        }



        if (answer && answer.id) {
          _tempRes.answer = { ...answer };
        } else {
          _tempRes.answer.answer = _tempRes.question.answer;
        }
        _tempRes.answer.collected = collected;
        _tempRes.answer.answerstate = answerstate;
        _tempRes.answer.notebookid = notebookid;
        _tempRes.answer.subject = subject;
        _tempRes.answer.lessonnumber = lessonnumber;
        _tempRes.answer.totallesson = totallesson;
        _tempRes.answer.answerid = answerid;
        _tempRes.answer.classid = classid;
        _tempRes.answer.courseid = courseid;

        // 选择题
        if (
          _tempRes.question.options_map &&
          _tempRes.answer &&
          _tempRes.answer.answer
        ) {
          _tempRes.question.mineOptionMap = [];
          let _keys: Array<string> = Object.keys(_tempRes.question.options_map);
          if (_keys.length >= 4) {
            _keys.forEach((attr: string, index: number): void => {
              _tempRes.question.mineOptionMap[
                index
              ] = `<div class="flex ai-c personel-option-list-item ${
                attr == _tempRes.answer.answer ? "error" : ""
              } ${
                attr == _tempRes.question.answer ? "success" : ""
              }"><p class="tac label">${attr}</p><p class="cont">${
                _tempRes.question.options_map[attr]
              }</p></div>`;
            });
          }
        }
        if (this.loadingFlag) {
          this.loadingFlag = false;
        }
        _this.$set(_this.curCollectionItems, idx, _tempRes);
        this.canClickFlag = false;
      }
  }

  private changeActiveIndex(index: number): void {
    // 笔记本、错题本切换
    if (!this.canClickFlag) {
      this.canClickFlag = true;
      this.curCollectionItems = [];
      this.loadingFlag = true;
      this.activeIndex = index;
      this.tempObj.type = index == 1 ? index : 2;
      this.getCollectionList()
    }
  }
  private async collectedEvent(index: number, item: any) {
    // 笔记收藏
    // 收藏、取消收藏
    let _this: any = this;
    if (item.answer.collected == "Y") {
      // 取消
      let _result: any = await Caxios.delete({
        url: `/api/note/cancel/${item.answer.notebookid}`,
      });
      if (_result) {

        let _answer: any = { ..._this.curCollectionItems[index].answer };

        _answer.collected = 'N'
        _answer.notebookid = ''
        _this.$set(_this.curCollectionItems[index], "answer", _answer);
        _this.$message.success("取消收藏成功");
      } else {
        _this.$message.warning("取消收藏失败");
      }
    } else {
      // 收藏
      let _data: any = {
          stdid: item.answer.stdid || this.tempObj.stdid,
          type: 2,
          questid: item.answer.questionid || item.question.id,
          courseid: item.answer.courseid,
          classid: item.answer.classid,
          subject: item.answer.subject,
          lessonnumber: item.answer.lessonnumber,
          totallesson: item.answer.totallesson,
          answerid: item.answer.answerid,
        },
        _result: any = await Caxios.post({
          url: `/api/note/collection`,
          data: _data,
        }),
        _answer: any = { ..._this.curCollectionItems[index].answer };
      if (_result.msg) {
        _this.$message.warning(_result.msg);
      } else {
        _this.$message.success("收藏成功");
        _answer.collected = 'Y'
        _answer.notebookid = _result
        _this.$set(_this.curCollectionItems[index], "answer", _answer);
      }
    }
  }
  private hasAnswerFlagEvent(index: number, flag: boolean): void {
    // 打印的时候是否带上解析
    let _this: any = this;
    _this.$set(_this.hasAnswerFlag, index, flag);
  }
  private printEvent(index: number, item: any): void {
    // 具体打印事件
    if (!this.printFlag[index]) {
      this.printNum += 1;
      this.formatPrintList(1, index, item);
    } else {
      this.printNum -= 1;
      this.formatPrintList(-1, index, item);
    }
    this.printIcon = this.printNum == 0 ? print_b : print_w;
    this.arrawIcon = this.printNum == 0 ? arraw_b : arraw_w;
    this.printFlag[index] = !this.printFlag[index];
  }
  private formatPrintList(type: number, index: number, item: any): void {
    /**
     * type 1的时候表示添加，-1的时候表示删除
     */
    switch (type) {
      case 1:
        this.printList[index] = item;
        break;
      case -1:
        this.printList[index] = null;
        break;
    }
  }
  private myAnswerListFlagEvent(index: number): void {
    // 我的答案
    let _this: any = this;
    _this.myAnswerListFlag.forEach((v: boolean, i: number): void => {
      if (i != index) {
        _this.$set(_this.myAnswerListFlag, i, false);
      } else {
        _this.$set(_this.myAnswerListFlag, i, !v);
      }
    });
  }
  private viewAnswerListFlagEvent(index: number): void {
    // 查看答案
    let _this: any = this;
    _this.viewAnswerListFlag.forEach((v: boolean, i: number): void => {
      if (i != index) {
        _this.$set(_this.viewAnswerListFlag, i, false);
      } else {
        _this.$set(_this.viewAnswerListFlag, i, !v);
      }
    });
  }
  private openWindowPrintEvent(): void {
    if (this.printNum > 0) {
      let _printList: Array<any> = [],
        _hasAnswerFlag: Array<any> = [];
      this.printList.forEach((item: any, index: number): void => {
        if (item) {
          _printList.push(item);
          _hasAnswerFlag.push(this.hasAnswerFlag[index]);
        }
      });
      localStorage.setItem(
        "fangtian_printList",
        JSON.stringify({
          printList: _printList,
          hasAnswerFlag: _hasAnswerFlag,
        })
      );
      setTimeout(() => {
        window.open("/print");
      }, 20);
    }
  }
  beforeMount () {

    this.myAnswerListFlag = [];
    this.viewAnswerListFlag = [];
    this.hasAnswerFlag = [];
    this.printFlag = [];
    this.curCollectionItems = []


    let _this: any = this,
      _store: any = _this.$store.getters["personal/personalData"];
    this.tempObj.stdid = _store.id;
    this.getCollectionList()
  }
}
