
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import { mapGetters, mapActions } from 'vuex'
    import UsePwd from './../../../components/password/index.vue'
    import EditName from './../../../components/editName/index.vue'
    import EditCard from './../../../components/editCard/index.vue'
    import EditSchool from './../../../components/editSchool/index.vue'
    import EditGrade from './../../../components/editGrade/index.vue'
    import EditJob from './../../../components/editJob/index.vue'

    import { checkCard, checkPhone } from './../../../utils/validate'
    import AliOssUpload from '@/utils/oss'

    import axios from "axios"

    const _ = require('lodash')
    @Component({
        name: 'EditComp',
        components: { UsePwd, EditName, EditCard, EditSchool, EditGrade, EditJob },
        computed: {
            ...mapGetters('personal', ['personalData']),
            ...mapGetters('common', ['lang']),
            ...mapGetters('course', ['grade'])
        },
        methods: {
            ...mapActions('personal', ['editbase'])
        }
    })
    export default class EditComp extends Vue {
        personalData!: any
        updateFlag!: string
        editbase!: any
        grade!: any
        lang!: any
        private editFlag = false
        private curPersonData: any = {}
        private hasSelected = false

        private operateData: any = {}

        private gradeList: Array<any> = [
            { text: this.$t('common.t16') }
        ]
        private setAllFlag (): void {
            const _this: any = this
            _this.$store.commit('personal/setAllFlag')
        }

        private nameMsg: any = ''
        private usenameCb (obj: any): void {
            const { phone, status } = obj
            this.operateData.name = phone
            if(phone) {
                this.nameMsg = ''
            } else {
                this.nameMsg = this.$t('center.t35')
            }
            if(status == 0) {
                this.nameMsg = ''
            }
        }

        private passwordMsg: any = ''
        private usepwdCb (obj: any): void {
            const { password, status } = obj
            this.operateData.password = password

            if(password) {
                if(password.length < 8 || password.length > 12) {
                    this.passwordMsg = this.$t('center.t36')
                // } else if(this.operateData.confirmpassword && this.operateData.confirmpassword != password) {
                //     this.passwordMsg = '两次密码输入不一致'
                } else {
                    this.passwordMsg = ''
                }
            } else {
                this.passwordMsg = this.$t('center.t37')
            }

            if(status == 0) {
                this.passwordMsg = ''
            }
        }

        private passwordMsg2: any = ''
        private usepwdCb2 (obj: any): void {
            const { password, status } = obj
            this.operateData.confirmpassword = password
            if(password) {
                if(password.length < 8 || password.length > 12) {
                    this.passwordMsg2 = this.$t('center.t36')
                // } else if(this.operateData.password && this.operateData.password != password) {
                //     this.passwordMsg2 = '两次密码输入不一致'
                } else {
                    this.passwordMsg2 = ''
                }
            } else {
                this.passwordMsg2 = this.$t('center.t37')
            }

            if(status == 0) {
                this.passwordMsg2 = ''
            }
        }

        private cardMsg = ''
        private EditcardCb (obj: any): void {
            const { identificationCard, status } = obj
            this.operateData.identification_card = identificationCard
            if(identificationCard) {
                if(!checkCard(identificationCard)) {
                    this.cardMsg = '身份证号格式错误'
                } else {
                    this.cardMsg = ''
                }
            } else {
                this.cardMsg = ''
            }
            if(status == 0) {
                this.cardMsg = ''
            }
        }

        private phoneMsg1 = ''
        private phoneCb1 (obj: any): void {
            const { phone, status } = obj
            this.operateData.phone = phone
            if(checkPhone(phone)) {
                this.phoneMsg1 = ''
            } else {
                this.phoneMsg1 = '手机号格式不正确'
            }
            if(status == 0) {
                this.phoneMsg1 = ''
            }
        }
        private phoneMsg2 = ''
        private phoneCb2 (obj: any): void {
            const { phone, status } = obj
            this.operateData.standbyPhone = phone
            if(phone) {
              /*  if(checkPhone(phone)) {
                    this.phoneMsg2 = ''
                } else {
                    this.phoneMsg2 = '手机号格式不正确'
                }*/
            } else {
                this.phoneMsg2 = ''
            }
            if(status == 0) {
                this.phoneMsg2 = ''
            }
        }

        private editschoolMsg = ''
        private editschoolCb(obj: any): void {
            const { schoolName, status } = obj
            this.operateData.schoolName = schoolName
            /*if(schoolName) {
                this.editschoolMsg = ''
            } else {
                this.editschoolMsg = '学校不能为空'
            }*/
            if(status == 0) {
                this.editschoolMsg = ''
            }
        }

        private editgradeMsg: any = ''
        private editgradeCb(obj: any): void {
            const { grade, status, text } = obj
            this.operateData.grade = grade
            this.operateData.grade_name = text
            if(grade) {
                this.editgradeMsg = ''
            } else {
                this.editgradeMsg = this.$t('center.t38')
            }
            if(status == 0) {
                this.editgradeMsg = ''
            }
        }

        private editjobMsg1 = ''
        private editjobCb1 (obj: any): void {
            const { jobId, status, clickNum, text } = obj
            this.operateData.f_job = jobId
            this.operateData.father_job = text
            if(jobId || (clickNum == 0 && !jobId)) {
                this.editjobMsg1 = ''
            } else {
                this.editjobMsg1 = '父亲职业不能为空'
            }
            if(status == 0) {
                this.editjobMsg1 = ''
            }
        }

        private editjobMsg2 = ''
        private editjobCb2 (obj: any): void {
            const { jobId, status, clickNum, text } = obj
            this.operateData.m_job = jobId
            this.operateData.mother_job = text
            if(jobId || (clickNum == 0 && !jobId)) {
                this.editjobMsg2 = ''
            } else {
                this.editjobMsg2 = '母亲职业不能为空'
            }
            if(status == 0) {
                this.editjobMsg2 = ''
            }
        }
        created(){
          const personalData: any = localStorage.getItem('singapore_personal_Data')
            if (personalData) {
                this.curPersonData = JSON.parse(personalData)
            } else {
                this.curPersonData = {}
            }
          this.gradeList = this.grade
        }

        @Watch('personalData', { immediate: true, deep: true })
        personalDataWatch(newVal: any): void {
            console.log('监听到变化')
            const personalData: any = localStorage.getItem('singapore_personal_Data')
            if (personalData) {
                this.curPersonData = JSON.parse(personalData)
            } else {
                this.curPersonData = {}
            }
        }
        @Watch('grade', { immediate: true, deep: true })
        gradeWatch(newVal: any): void {
            this.gradeList = []
            if(newVal.length > 0) {
                newVal.forEach((item: any, index: number): void => {
                    if(item.text == '不限') {
                        item.text = '请选择年级'
                    }
                    this.gradeList.push(item)
                })
            }
        }
        @Watch('updateFlag', { immediate: true, deep: true })
        setUpdateFlagWatch(newVal: string): void {
            const _this: any = this
            if(newVal) {
                if(newVal == '信息修改成功') {
                    _this.$message.success(this.lang === 'en-US' ? 'Information amended successfully' : newVal)
                } else {
                    _this.$message.warning(newVal)
                }
            }
        }
        private setOperateData (newVal: any): void {
            Object.keys(newVal).forEach((attr: string): void => {
                if(attr != 'name' && attr != 'grade_name' && attr != 'stdname' && attr != 'gradename') {
                    this.operateData[attr] = newVal[attr]
                } else {
                    if(attr == 'name' || attr == 'stdname') {
                        this.operateData.name = newVal.name || newVal.stdname
                    }
                    if(attr == 'grade_name' || attr == 'gradename') {
                        this.operateData.grade_name = newVal.name || newVal.stdname
                    }
                }
            })
            console.log(this.operateData)
        }

        private openAndSelectFile (): void {
            const _this: any = this;
            _this.$refs.uploadFileDom.click()
        }
        private async fileChangeEvent(ev: any) {
            console.log('ev', ev)
            const url = await AliOssUpload.upload(ev.target.files[0]);
            if (url) {
                console.log('url', url)
                this.curPersonData.icon = url
            }
        }
        private setSex (val: any) {
            this.curPersonData.sex = val
        }

        // 年级下拉
        private gradeChange (e: any) {
            this.curPersonData.gradeName = this.gradeList.find(cell => cell.value === e).label
            console.log('年级', this.curPersonData)
        }

        private editEvent () {
            const _this: any = this
            if(this.editFlag) {
                // 添加判断条件
                if(this.nameMsg) {
                    _this.$message.warning(this.nameMsg)
                    return
                }
                if(this.passwordMsg) {
                    _this.$message.warning(this.passwordMsg)
                    return
                }
                if(this.passwordMsg2) {
                    _this.$message.warning(this.passwordMsg2)
                    return
                }
                if(this.cardMsg) {
                    _this.$message.warning(this.cardMsg)
                    return
                }

                if(this.phoneMsg1) {
                    _this.$message.warning(this.phoneMsg1)
                    return
                }
                if(this.phoneMsg2) {
                    _this.$message.warning(this.phoneMsg2)
                    return
                }
                if(this.editschoolMsg) {
                    _this.$message.warning(this.editschoolMsg)
                    return
                }
                if(this.editgradeMsg) {
                    _this.$message.warning(this.editgradeMsg)
                    return
                }
                if(this.editjobMsg1) {
                    _this.$message.warning(this.editjobMsg1)
                    return
                }
                if(this.editjobMsg2) {
                    _this.$message.warning(this.editjobMsg2)
                    return
                }
                this.operateData.icon = this.curPersonData.icon
                this.operateData.sex = this.curPersonData.sex
                this.operateData.grade = this.curPersonData.grade
                this.operateData.gradeName = this.curPersonData.gradeName
                this.operateData.schoolName = this.curPersonData.schoolName
                this.operateData.icon = this.curPersonData.icon
                console.log('确认', this.operateData)
                this.editbase(_.cloneDeep(this.operateData))
                this.editFlag = !this.editFlag
                this.curPersonData = _.cloneDeep(this.operateData)
            } else {
                for(const attr in this.operateData) {
                    this.operateData[attr] = ''
                }
                _this.$store.commit('personal/setAttrEmptyFlag')
                this.editFlag = !this.editFlag
                this.setOperateData(this.curPersonData)
            }
        }

        beforeDestroy() {
            const _this: any = this
            _this.$store.commit('personal/setUpdateFlag', '')
        }
    }
