
import { Component, Vue, Watch } from "vue-property-decorator"
import { Caxios } from "./../../../utils/axios";
@Component({
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    branchschoolCode: {
      type: String,
      default: ''
    }
  }
})
export default class HkHowpay extends Vue {
  branchschoolCode!: any;
  @Watch('visible')
  visibleChange(newVal: any, oldVal: any) {
    if (newVal) {
      console.log('show')
      this.getPayCode()
    }
  }
  private handleOk(): void {
    this.$emit('registSuc')
  }
  private cancel(): void {
    this.$emit('cancel')
  }
  public list: any = []
  public copyEvent(id: string): void {
    const html = document.getElementById(id)?.innerHTML
    const transfer = document.createElement('input')
    document.body.appendChild(transfer)
    transfer.value = html as string // 这里表示想要复制的内容
    transfer.focus();
    transfer.select();
    if (document.execCommand('copy')) {
      document.execCommand('copy')
    }
    transfer.blur()
    document.body.removeChild(transfer)
    this.$message.success('复制成功！')

  }
  async getPayCode() {
    const res: any = await Caxios.post({
      url: '/api/official/order/payConfig', data: {
        branchSchoolCode: this.branchschoolCode
      }
    })
    if (res.code !== '10000' || !res.data || res.data?.length <= 0) return
    this.list = res.data.map((item: any) => {
      return { label: item.code, value: item.name, remark: item.remark || '' }
    })
  }
}
