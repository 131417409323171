
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Caxios } from "./../../utils/axios";
import VueQr from "vue-qr";
import QRCode from "qrcode"

let logoSrc: any = require("./../../assets/imgs/logo.png");
@Component({
  name: "PayDialog",
  components: { VueQr },
})
export default class PayDialog extends Vue {
  @Prop() payDialogItem!: any;
  private stepList: Array<any> = [
    ["以现金形式缴纳学费的，费用将以现金方式退回。"],
    [
      "退费方式计算：用户应退金额 = 实际缴费金额－课程单价*系统发生课次课程单价：",
      "180 元/课次，部分年级220元/课次",
      "系统发生课次：以办理当日方田系统内发生课次数为准",
    ],
    [
      "办理退费时，必须在前台服务中心现场办理，同时必须出示学员本期班听课证，且必",
      "须接受方田工作人员电话核实办理退费人身份。",
      "不接受任何形式的口头预约退费。",
    ],
  ];
  private vueqrObj: any = {};
  private imgUrl: string = ''
  private vueqrOptions: any = {
    size: 172,
    margin: 0,
    logoScale: 0.2,
    logoSrc: logoSrc,
  };
  private timer: any = null;
  @Emit()
  private closeEvent(type: number): number {
    return type;
  }

  private async getCode() {
    this.vueqrObj = {}
    let _this: any = this,
      _res: any = await Caxios.get({
        url: `/api/official/sybpay/getqrcode?orderId=${this.payDialogItem.orderid}`,
      });
    if (_res) {
      // this.vueqrObj = JSON.parse(_res);
      this.vueqrObj.billQRCode = _res
      if (!this.vueqrObj.billQRCode) {
        _this.$message.warning("二维码获取,银商平台返回失败");
      } else {
        QRCode.toDataURL(_res)
            .then((url: any) => {
              this.imgUrl = url
              if (!this.timer) {
                this.getBillStatus();
                this.timer = setInterval(() => {
                  this.getBillStatus();
                }, 10000);
              }
            })
            .catch((err: any) => {
              console.error(err)
            })
      }
    }
  }
  private async getBillStatus() {
    let _res: any = await Caxios.get({
      url: `/api/official/sybpay/getbillstatus?orderId=${this.payDialogItem.orderid}`,
    });
    if (_res) {
      let _v: any = JSON.parse(_res);
      if (_v.errCode == "SUCCESS" && _v.billStatus == "PAID") {
        this.clearTimer();
        this.closeEvent(1);
      }
    }
  }
  private clearTimer(): void {
    clearInterval(this.timer);
    this.timer = null;
  }

  mounted() {
    this.getCode();
  }
  beforeDestroy() {
    this.clearTimer();
  }
}
