import OSS from 'ali-oss'
import { Caxios } from "./axios";
const OSS_UPLOAD_REGION = 'oss-cn-beijing';
const OSS_UPLOAD_BUCKET = 'fangtian-singapore-app';
const OSS_PROJECT_PREFIX = 'profile_photo';

export default class AliOssUpload {
  /** 实例对象 **/
  static aliOssClient = undefined;

  /** 基础信息 **/
  static aliOssConfig = {
    accessKeyId: '',
    accessKeySecret: '',
    stsToken: '',
    rootPath: '',
    datePath: '',
    projectPrefix: ''
  }

  static async init() {
    const {
      data
    } = await Caxios.post({
      url: '/api/common/oss/sts/token'
    });
    AliOssUpload.aliOssConfig = {
      accessKeyId: data.accessKeyId,
      accessKeySecret: data.accessKeySecret,
      stsToken: data.securityToken,
      rootPath: data.rootPath,
      datePath: data.datePath,
      projectPrefix: OSS_PROJECT_PREFIX
    };
    AliOssUpload.aliOssClient = new OSS({
      region: data.region,
      accessKeyId: data.accessKeyId,
      accessKeySecret: data.accessKeySecret,
      stsToken: data.securityToken,
      bucket: data.bucketName,
      secure: true,
      cname: false
    });
  }

  static async refreshSTSToken() {
    // 向您搭建的STS服务获取临时访问凭证。
    const {
      data
    } = await Caxios.post({
      url: '/api/common/oss/sts/token'
    });
    Reflect.set(AliOssUpload.aliOssConfig, 'accessKeyId', data.accessKeyId);
    Reflect.set(AliOssUpload.aliOssConfig, 'accessKeySecret', data.accessKeySecret);
    Reflect.set(AliOssUpload.aliOssConfig, 'stsToken', data.securityToken);
    Reflect.set(AliOssUpload.aliOssConfig, 'rootPath', data.rootPath);
    Reflect.set(AliOssUpload.aliOssConfig, 'datePath', data.datePath);
    AliOssUpload.aliOssClient = new OSS({
      region: data.region,
      accessKeyId: data.accessKeyId,
      accessKeySecret: data.accessKeySecret,
      stsToken: data.securityToken,
      bucket: data.bucketName
    });
  }

  // 上传文件
  static async upload(file: any) {
    await AliOssUpload.init();
    const suffix = file.name.split('.').pop()
    const fileName = new Date().getTime() + '_' + Math.floor(Math.random() * 1000) + '.' + suffix
    console.log('AliOssUpload.aliOssConfig.projectPrefix',AliOssUpload.aliOssConfig.projectPrefix)
    try {
      // @ts-ignore
      const res = await AliOssUpload.aliOssClient.put(`${AliOssUpload.aliOssConfig.rootPath}/${AliOssUpload.aliOssConfig.projectPrefix}/${AliOssUpload.aliOssConfig.datePath}/${fileName}`, file);
      return res.url;
    } catch (e) {
      console.log(e, '上传失败')
      return false;
    }
  }
}
