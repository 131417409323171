
    import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
    import { mapGetters } from "vuex";
    import CourseSelect from "@/components/courseSelect/index.vue";
    let timeInterval: any = null
    @Component({
        computed: {
            ...mapGetters('common', ['lang'])
        }
    })
    export default class UsePwd extends Vue {
        lang!: any
        @Prop() endTime!: any
        @Prop() showText!: boolean
        @Prop() showSecond!: boolean
        private text:any = ''
        private timeInterval: any = null
        @Watch('endTime', { immediate: true, deep: true })
        endTimeWatch(newVal: boolean): void {
            this.init()
        }
        private init (): void {
            if (this.endTime === '' || this.endTime == null) return
            timeInterval = setInterval(() => {
                const nowTime = new Date()
                const endTime = new Date(this.endTime.replace(/-/g, '/'))
                const t = endTime.getTime() - nowTime.getTime()
                if (t > 0) {
                    const day = Math.floor(t / 86400000)
                    let hour: any = Math.floor((t / 3600000) % 24)
                    let min: any = Math.floor((t / 60000) % 60)
                    let sec: any = Math.floor((t / 1000) % 60)
                    hour = hour < 10 ? '0' + hour : hour
                    min = min < 10 ? '0' + min : min
                    sec = sec < 10 ? '0' + sec : sec
                    let format = ''
                    if (day > 0) {
                        if (this.showSecond) {
                            format = this.lang === 'en-US' ? `${day}Day ${hour}:${min}:${sec}` : `${day}天${hour}小时${min}分${sec}秒`
                        } else {
                            format = this.lang === 'en-US' ? `${day}Day ${hour}:${min}` : `${day}天${hour}小时${min}分`
                        }
                    }
                    if (day <= 0 && hour > 0) {
                        if (this.showSecond) {
                            format = this.lang === 'en-US' ? `${hour}:${min}:${sec}` : `${hour}小时${min}分${sec}秒`
                        } else {
                            format = this.lang === 'en-US' ? `${hour}:${min}` : `${hour}小时${min}分`
                        }
                    }
                    if (day <= 0 && hour <= 0) {
                        if (this.showSecond) {
                            format = this.lang === 'en-US' ? `${min}:${sec}` : `${min}分${sec}秒`
                        } else {
                            format = this.lang === 'en-US' ? `${sec}` : `${min}分`
                        }
                    }
                    this.text = format
                } else {
                    clearInterval(timeInterval)
                    timeInterval = null
                    // this.getTime()
                    this.text = '超时'
                    this.$emit('endTime')
                }
            }, 1000)
        }
        mounted() {
            this.init()
        }
        destroyed() {
            clearInterval(this.timeInterval)
            this.timeInterval = null
        }
    }
