
    import { Component, Vue, Watch } from "vue-property-decorator"
    import { Caxios } from "./../../../utils/axios";
    @Component({
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            branchschoolCode: {
                type: String,
                default: ''
            }
        }
    })
    export default class PModal extends Vue {
        public codeValue: any = ''
        public imgsrc: any = ''
        public AccountNumber: any = ''
        branchschoolCode!: any;
        visible!: any
        @Watch('visible')
        visibleChange (newVal: any, oldVal: any) {
            this.getPayCode()
        }
        created() {
            if (this.visible) {
                this.getPayCode()
            }
        }
        private handleOk(): void {
            this.$emit('registSuc')
        }
        private cancel(): void {
            this.$emit('cancel')
        }
        public copyEvent (id: string): void {
            const html = document.getElementById(id)?.innerHTML
            const transfer = document.createElement('input')
            document.body.appendChild(transfer)
            transfer.value = html as string // 这里表示想要复制的内容
            transfer.focus();
            transfer.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy')
            }
            transfer.blur()
            document.body.removeChild(transfer)
            this.$message.success('Copied！')

        }
        async getPayCode () {
            const res: any = await Caxios.post({ url: '/api/official/order/payConfig' , data: {
                    branchSchoolCode: this.branchschoolCode
                }})
            if (res.code !== '10000' || !res.data || res.data?.length <= 0) return
            const codeitem = res.data.find((item: any) => item.code === 'UEN')
            const imgitem = res.data.find((item: any) => item.code === 'PayNow_QR_Code')
            const AccountNumber = res.data.find((item: any) => item.code === 'OCBC_Bank_Account_Number')
            this.codeValue = codeitem.name
            this.imgsrc = imgitem.name
            this.AccountNumber = AccountNumber.name
        }
    }
