
    import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator'
    import { mapGetters } from 'vuex'

    @Component({
        name: 'SelectSubject',
        computed: mapGetters('personal', ['allFlag'])
    })
    export default class SelectSubject extends Vue {
        allFlag!: any
        @Prop() curSubjects!: Array<any>

        private subjectText: string = '学科'
        private subject: string = ''
        private itemClickedFalg: boolean = false
        private mouseoverFlag: boolean = false
        private activeIndex: number = -1
        private listFlag: boolean = false
        private curSubjectList: Array<any> = [
            // { text: '学科', id: ''}
        ]

        @Watch('allFlag', { immediate: true, deep: true })
        allFlagWatch(newVal: number): void {
            if(newVal != -1) {
                this.listFlag = false
                this.mouseoverFlag = false
                this.selectsubjectCb(1)
            }
        }
        @Watch('curSubjects', { immediate: true, deep: true })
        curSubjectsWatch(newVal: Array<any>): void {
            // this.curSubjectList = [ { text: '学科', id: ''} ]
            this.curSubjectList = []
            if(newVal.length > 0) {
                this.curSubjectList = [
                    ...this.curSubjectList,
                    ...newVal
                ]
            }
        }


        private mouseoverEvent (): void {
            this.mouseoverFlag = true
            this.selectsubjectCb(0)
        }
        @Emit()
        private selectsubjectCb(status: number): any {
            return { subject: this.subject, status: status, text: this.subjectText }
        }
        private listFlagEvent (): void {
            this.listFlag = !this.listFlag
        }
        private changeActiveEvent (item: any, index: number): void {
            this.subjectText = item.text
            this.subject = item.id
            this.itemClickedFalg = true
            this.mouseoverFlag = false
            this.listFlag = false
            this.selectsubjectCb(1)
        }
    }
